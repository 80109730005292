import React from "react";

const EditButton = ({ onClick, isOther = false }) => {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className={`d-flex edit-btn px-3 py-2 rounded-pill ${
        isOther ? "other-edit-btn" : ""
      }`}
    >
      Edit{" "}
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.1189 12.9449H0.520963C0.256107 12.9449 0.0488281 13.1636 0.0488281 13.417C0.0488281 13.6703 0.267622 13.8891 0.520963 13.8891H13.1304C13.3953 13.8891 13.6025 13.6703 13.6025 13.417C13.6025 13.1636 13.3837 12.9449 13.1189 12.9449Z"
          fill= "#78808C"
        />
        <path
          d="M0.0486248 8.76474L0.0371094 11.0103C0.0371094 11.1369 0.0831713 11.2636 0.175295 11.3557C0.267419 11.4478 0.382574 11.4939 0.509244 11.4939L2.74325 11.4824C2.86992 11.4824 2.98507 11.4363 3.07719 11.3442L10.7926 3.62884C10.9768 3.44459 10.9768 3.14519 10.7926 2.94943L8.58159 0.715426C8.39734 0.531178 8.09794 0.531178 7.90218 0.715426L6.3591 2.27001L0.186811 8.43079C0.106202 8.52292 0.0486248 8.63807 0.0486248 8.76474ZM8.24764 1.72879L9.80223 3.28338L8.92705 4.15855L7.37247 2.60396L8.24764 1.72879ZM1.00441 8.97202L6.69305 3.28338L8.24764 4.83796L2.559 10.5151L0.992893 10.5266L1.00441 8.97202Z"
          fill="#78808C"
        />
      </svg>
    </button>
  );
};

export default EditButton;
