export const formConstants = {
  FETCH_COUNTRIES_REQUEST: "FETCH_COUNTRIES_REQUEST",
  FETCH_COUNTRIES_SUCCESS: "FETCH_COUNTRIES_SUCCESS",
  FETCH_COUNTRIES_FAILURE: "FETCH_COUNTRIES_FAILURE",

  FETCH_STATES_REQUEST: "FETCH_STATES_REQUEST",
  FETCH_STATES_SUCCESS: "FETCH_STATES_SUCCESS",
  FETCH_STATES_FAILURE: "FETCH_STATES_FAILURE",

  FETCH_PRACTICE_REQUEST: "FETCH_PRACTICE_REQUEST",
  FETCH_PRACTICE_SUCCESS: "FETCH_PRACTICE_SUCCESS",
  FETCH_PRACTICE_FAILURE: "FETCH_PRACTICE_FAILURE",

  FETCH_JURISDICTIONS_REQUEST: "FETCH_JURISDICTIONS_REQUEST",
  FETCH_JURISDICTIONS_SUCCESS: "FETCH_JURISDICTIONS_SUCCESS",
  FETCH_JURISDICTIONS_FAILURE: "FETCH_JURISDICTIONS_FAILURE",

  CLEAR_ERRORS: "CLEAR_ERRORS",
};
